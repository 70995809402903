import React, { Component } from 'react';

class Workinprogress extends Component {
  render() {


    return (
      <section id="workinprogress">

         <div className="row section-head">

            <div className="two columns header-col">

               <h1><span>Work In Progress stay tuned...</span></h1>

            </div>

         </div>
         </section>
    )}
   }

export default Workinprogress;
